import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tips-trick',
  templateUrl: './tips-trick.component.html',
  styleUrls: ['./tips-trick.component.css']
})
export class TipsTrickComponent implements OnInit {

  titleSectionProp: any = {
    class: 'text-left',
    title: 'Recibe nuevos candidatos todos los días.',
    description: 'Solo envianos tu búsqueda y nuestro sistema se encargará del resto.'
  };

  Services: any[] = [
    { title : 'Rápida entrega.' },
    { title : 'Exposición 0.' },
    { title : 'Paga solo por lo que usas.' },
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
