import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.css']
})
export class FeaturesComponent implements OnInit {

  titleSectionProp: any = {
    title: 'Inteligencia en Reclutamiento IT',
    description: 'Nuestros Sistemas poseen el más alto nivel de precisión para garantizar la entrega de postulantes de alta compatibilidad con tus búsquedas en el sector tecnológico.'
  };

  List : any[] = [
    {
      title: 'Puestos Extraordinarios',
      icon: 'flaticon flaticon-accounting',
      hoverClass: 'purple-hover',
      desc: 'De especialización altamente específica.'
    },
    {
      title: 'Profesionales Demandados.',
      icon: 'flaticon flaticon-professional',
      hoverClass: 'org-hover',
      desc: 'Accede a quienes tienen muchas oportunidades.'
    },
    {
      title: 'Aptitudes Validadas.',
      icon: 'flaticon flaticon-document-1',
      hoverClass: 'purple-hover',
      desc: 'Ocupate solo de elegir.'
    },
    {
      title: 'Un SI o nada.',
      icon: 'flaticon flaticon-growth-1',
      hoverClass: 'green-hover',
      desc: 'Postulantes verdaderamente interesados.'
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
