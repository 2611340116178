<section id="iq-testimonial" class="pb-0" style="padding-top: 0;">
    <div class="container">
        <div class="row align-items-center">

            <div class="col-lg-6">
                <app-v-two-section-title [titleSectionProp]="titleSectionProp"></app-v-two-section-title>
                <div class="text-left">
                    <ul class="iq-list">
                        <li *ngFor="let service of Services"><i class="fa fa-check"></i><span>{{ service.title }} </span></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6">
                <img src="./assets/sofbox-v2/images/home-8/909.png" class="img-fluid" alt="blogimage1">
            </div>
        </div>
    </div>
</section>
