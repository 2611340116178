<div id="loading">
    <div id="loading-center">
        <img src="./assets/sofbox-v2/images/loader.png" alt="loader">
    </div>
</div>
<!-- Header Component Start-->
<app-v-two-header-style1 [logoImg]="logoWhite" [logoClass]="logoClass" [logoWhite]="navLogo" className="main-header" [navItemList]="navItems"></app-v-two-header-style1>
<!-- Header Component End-->
<!-- START sofbox4 REVOLUTION SLIDER 6.1.3 -->
<app-slider></app-slider>
<!--End sofbox2 REVOLUTION SLIDER -->
<!-- Main Content -->
<div class="main-content pt-5" id="iq-about">
    <app-about></app-about>
    <app-features></app-features>
    <app-testimonial></app-testimonial>
    <app-pricing></app-pricing>
    <app-blog></app-blog>

    <app-v-two-contact-map [sectionProp]="sectionProp" [contactDetail]="contactDetail">
        <div titleSection>
            <app-v-two-section-title [titleSectionProp]="titleSectionProp"></app-v-two-section-title>
        </div>
        <ul socailLink  class="share-social list-inline p-0">
            <li class="list-inline-item"><a href="#"><i class="fa fa-facebook-f"></i></a></li>
            <li class="list-inline-item"><a href="#"><i class="fa fa-twitter"></i></a></li>
            <li class="list-inline-item"><a href="#"><i class="fa fa-github"></i></a></li>
            <li class="list-inline-item"><a href="#"><i class="fa fa-instagram"></i></a></li>
        </ul>
        <form contactForm>
            <div class="row">
                <div class="col-lg-6"> <input type="text" class="form-control" id="inputName" placeholder="Your Name"> </div>
                <div class="col-lg-6"> <input type="Email" class="form-control" id="inputEmail" placeholder="Your Email"></div>
                <div class="col-lg-12"> <input type="text" class="form-control" id="inputSubject" placeholder="Your Subject"></div>
                <div class="col-lg-12"> <textarea class="form-control" id="exampleFormControlTextarea1" rows="7" placeholder="Your Message"></textarea>
                </div>
                <div class="col-lg-12">
                    <a class="button blue-btn" href="#">Send Message</a>
                </div>
            </div>
        </form>
    </app-v-two-contact-map>
</div>
<app-v-two-footer-style2 [footerLogo]="footerLogo" [footerText]="footerText" [footerTitle]="footerTitle" [footerDescription]="footerDescription">
    <ul menu>
        <li><a class="text-white" href="javascript:void(0)">About Us</a></li>
        <li><a class="text-white" href="javascript:void(0)">Theme</a></li>
        <li><a class="text-white" href="javascript:void(0)">Features</a></li>
        <li><a class="text-white" href="javascript:void(0)">Pricing</a></li>
        <li><a class="text-white" href="javascript:void(0)">Blog</a></li>
    </ul>
    <ul about_us>
        <li><a class="text-white" href="javascript:void(0)">About Sofbox</a></li>
        <li><a class="text-white" href="javascript:void(0)">Roadmap</a></li>
        <li><a class="text-white" href="javascript:void(0)">How it Work</a></li>
        <li><a class="text-white" href="javascript:void(0)">Team</a></li>
        <li><a class="text-white" href="javascript:void(0)">News</a></li>
    </ul>
    <div testimonial>
        <app-v-two-testimonial-carousel [testimonialList]="List"></app-v-two-testimonial-carousel>
    </div>
</app-v-two-footer-style2>