import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-work',
  templateUrl: './how-work.component.html',
  styleUrls: ['./how-work.component.css']
})
export class HowWorkComponent implements OnInit {

  titleSectionProp = {
    title: '¿Cómo funciona?',
    description: 'Nuestra combinación única de avanzados algoritmos y experimentados especialistas en el area resulta en la mejor opción para nuestros clientes.',
    class: 'text-left'
  };

  List : any[] = [
    { title: 'Cargamos tus Búsquedas.', icon: 'flaticon flaticon-business', hoverClass: 'purple-hover' },
    { title: 'Activamos nuestro Sistema.', icon: 'flaticon flaticon-plane', hoverClass: 'org-hover' },
    { title: 'Entregamos tus Candidatos.', icon: 'flaticon flaticon-reward', hoverClass: 'green-hover' }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
