import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feature',
  templateUrl: './feature.component.html',
  styleUrls: ['./feature.component.css']
})
export class FeatureComponent implements OnInit {

  titleSectionProp: any = {
    class: 'text-center',
    title: 'Inteligencia en Reclutamiento IT',
    description: 'Nuestros Sistemas poseen el más alto nivel de precisión para garantizar la entrega de postulantes de alta compatibilidad con tus búsquedas en el sector tecnológico.',
  };
  
  List: any[] = [
    { title: 'Puestos Extraordinarios.', hoverClass: 'blue-hover' ,icon: 'flaticon flaticon-growth', desc: 'De especialización altamente específica.'},
    { title: 'Profesionales Demandados.', hoverClass: 'green-hover' ,icon: 'flaticon flaticon-professional', desc: 'Accede a quienes tienen muchas oportunidades.'},
    { title: 'Aptitudes Validadas.', hoverClass: 'org-hover' ,icon: 'flaticon flaticon-business', desc: 'Ocupate solo de elegir.'},
    { title: 'Un SI o nada.', hoverClass: 'blue-hover' ,icon: 'flaticon flaticon-reward', desc: 'Postulantes verdaderamente interesados.'},
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
