import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.css']
})
export class TestimonialComponent implements OnInit {
  titleSectionProp: any = {
    class: 'text-left',
    title: 'Recibe nuevos candidatos todos los días.',
    description: 'Solo envianos tu búsqueda y nuestro sistema se encargará del resto.'
  };

  Services: any[] = [
    { title : 'Rápida entrega.' },
    { title : 'Exposición 0.' },
    { title : 'Paga solo por lo que usas.' },
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
